import { Button, DatePicker, Flex, Form, Input, Select } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { useRef } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';

import styles from './Route.module.scss';
import useCreateRoute from 'api/route/useCreateRoute';
import { RouteModel } from 'models/RouteModel';
import { DefaultOptionType } from 'antd/lib/select';
import { Tariff } from 'types/enums/route';
import { RoutePropNames, RoutePointPropNames } from './lib/enums';

const tariffOptions: DefaultOptionType[] = Object.entries(Tariff).map(([value, label]) => ({ value, label }));

const localityOptions: DefaultOptionType[] = [
  {
    label: 'Москва',
    value: '1',
  },
  {
    label: 'Санкт-Петербург',
    value: '2',
  },
  {
    label: 'Новосибирск',
    value: '3',
  },
  {
    label: 'Сочи',
    value: '4',
  },
  {
    label: 'Владивосток',
    value: '5',
  }
];

const emptyRoute: RouteModel = {
  id: '',
  description: '',
  points: [
    {
      locality: '',
      description: '',
    },
    {
      locality: '',
      description: '',
    },
  ],
  userId: '',
  price: 0,
  driversCount: 0,
  passengersCount: 0,
  tariff: tariffOptions[0].value as keyof Tariff,
  date: '',
};

export const Route = () => {
  const [form] = Form.useForm<RouteModel>();
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    result: route,
    request: createRoute,
    isFetching: isFetchingCreateRoute,
  } = useCreateRoute();

  const handleSaveButtonClick = () => {
    form.submit();
  };

  const handleFormSubmit = (route: RouteModel) => {
    createRoute(route);
  };

  const passengersCountInputFormatter = (value: string | undefined) => {
    return Number(value);
  };

  return (
    <main
      ref={containerRef}
      className={clsx(styles.main)}
    >
      <Form<RouteModel>
        form={form}
        className={clsx(styles.form)}
        initialValues={{
          id: '',
          description: '',
          points: [
            {
              locality: '',
              description: '',
            },
            {
              locality: '',
              description: '',
            },
          ],
          userId: '',
          date: '',
        }}
        onFinish={handleFormSubmit}
      >
        <Flex
          align="center"
          className={clsx(styles.formRow, styles.headerRow)}
        >
          <h1 className={clsx(styles.title)}>
            Ваш маршрут
          </h1>
          <Form.Item
            className={clsx(styles.formItemInput)}
            name={RoutePropNames.date}
            rules={[
              { required: true, message: <></> },
            ]}
          >
            <DatePicker
              size="large"
              placeholder="Выберите время"
              className={clsx(styles.datePicker)}
              minDate={dayjs(new Date())}
              showTime={{ format: 'HH:mm' }}
              format="DD.MM.YYYY   HH:mm"
            />
          </Form.Item>
        </Flex>
        <Flex
          align="center"
          className={clsx(styles.formRow)}
        >
          <div className={clsx(styles.formItemCaption)}>
            Тариф
          </div>
          <Form.Item
            className={clsx(styles.formItemInputSmall)}
            name={RoutePropNames.tariff}
            rules={[
              { required: true, message: <></> },
            ]}
          >
            <Select
              size="large"
              options={tariffOptions}
            />
          </Form.Item>
        </Flex>
        <Flex
          align="center"
          className={clsx(styles.formRow)}
        >
          <div className={clsx(styles.formItemCaption)}>
            Количество пассажиров
          </div>
          <Form.Item
            className={clsx(styles.formItemNumber)}
            name={RoutePropNames.passengersCount}
            normalize={(value: string) => {
              console.log('value', value);
              const rawValue = value?.trim();
              return rawValue.replace(/\D/g, '');
            }}
            rules={[
              { required: true, message: <></> },
            ]}
          >
            <Input
              size="large"
              max={8}
              // formatter={passengersCountInputFormatter}
            />
          </Form.Item>
        </Flex>
        <p className={clsx(styles.formCaption)}>Точки маршрута</p>
        <Form.List name="points">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, i, arr) => {
                const { name, key, ...restField } = field;
                const isStartPoint = i === 0;
                const isEndPoint = i + 1 === fields.length;

                return (
                  <div
                    key={i}
                    className={clsx(styles.pointWrapper)}
                  >
                    <Flex
                      align="center"
                      justify="space-between"
                      className={clsx(styles.pointTitle)}
                    >
                      <div>
                        <span className={clsx(styles.pointCaption)}>
                          Точка {i + 1}
                        </span>
                        {isStartPoint && (
                          <span className={clsx(styles.endPointCaption)}>&nbsp;(Начало маршрута)</span>
                        )}
                        {isEndPoint && (
                          <span className={clsx(styles.endPointCaption)}>&nbsp;(Конец маршрута)</span>
                        )}
                      </div>
                      {i > 1 && (
                        <div className={clsx(styles.removePointButtonWrapper)}>
                          <Button
                            type="text"
                            icon={<CloseOutlined />}
                            onClick={() => remove(i)}
                          />
                        </div>
                      )}
                    </Flex>
                    <Flex
                      align="center"
                      className={clsx(styles.formRow)}
                    >
                      <div className={clsx(styles.formItemCaption)}>
                        Населённый пункт
                      </div>
                      <Form.Item
                        className={clsx(styles.formItemInput)}
                        name={[name, RoutePointPropNames.locality]}
                        rules={[
                          { required: true, message: <></> },
                        ]}
                      >
                        <Select
                          size="large"
                          options={localityOptions}
                        />
                      </Form.Item>
                    </Flex>
                    <Flex
                      align="center"
                      className={clsx(styles.formRow)}
                    >
                      <div className={clsx(styles.formItemCaption)}>
                        Адрес или описание места
                      </div>
                      <Form.Item
                        className={clsx(styles.formItemInput)}
                        name={[name, RoutePointPropNames.description]}
                        rules={[
                          { required: true, message: <></> },
                        ]}
                      >
                        <TextArea
                          size="large"
                          placeholder="Например: Аэропорт, улица Центральная, 143"
                          rows={3}
                        />
                      </Form.Item>
                    </Flex>
                  </div>
                );
              })}
              <div className={clsx(styles.addPointButtonWrapper)}>
                <Button
                  type="dashed"
                  onClick={add}
                  icon={<PlusOutlined />}
                  className={clsx(styles.addPointButton)}
                >
                  Добавить точку
                </Button>
              </div>
              <div className={clsx(styles.saveButtonWrapper)}>
                <Button
                  size="large"
                  className={clsx(styles.saveButton)}
                  onClick={handleSaveButtonClick}
                >
                  Сохранить маршрут
                </Button>
              </div>
            </>
          )}
        </Form.List>
      </Form>
    </main>
  );
};
