import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/ru';

import useFetchUserData from 'api/route/useFetchUserData';
import { Header } from '../header';
import { Footer } from '../footer';
import { appActions } from 'app/providers/slices/app';

moment.locale('ru');

export const PageLayout = () => {
  const dispatch = useDispatch();

  const {
    result: userData,
    request: fetchUserInfo,
  } = useFetchUserData();

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (userData) {
      dispatch(appActions.setUserData(userData));
    }
  }, [userData]);

  const handleLogout = useCallback(() => {
    dispatch(appActions.clearUserData());
  }, [dispatch]);

  return (
    <>
      <Header onLogout={handleLogout} />
      <Outlet />
      <Footer />
    </>
  );
};
