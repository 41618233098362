import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RouteModel } from 'models/RouteModel';

import { EntityName } from 'types/enums/storage';

export interface DataState {
  [EntityName.routes]?: RouteModel[],
}

export const dataSlice = createSlice({
  name: 'data',
  initialState: {} as DataState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setEntities(state, { payload }: PayloadAction<{ entityName: EntityName, data: any }>) {
      state[payload.entityName] = payload.data;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setEntity(state, { payload }: PayloadAction<{ entityName: EntityName, data: any }>) {
      if (!state[payload.entityName]) {
        state[payload.entityName] = [];
      }
      const index = state[payload.entityName]!.findIndex(
        (entity: { id?: string }) => entity.id === payload.data.id
      );

      if (index! < 0) {
        state[payload.entityName]!.push(payload.data);
      } else {
        state[payload.entityName]![index] = payload.data;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    removeEntity(state, { payload }: PayloadAction<{ entityName: EntityName, data: any }>) {
      if (!Array.isArray(state[payload.entityName])) {
        return;
      }

      const index = state[payload.entityName]!.findIndex(
        (entity) => (entity as { id: string }).id === payload.data.id
      );
      state[payload.entityName]?.splice(index, 1);
    },
    clearData(state, { payload }: PayloadAction<EntityName[] | undefined>) {
      const clearEntityNames = payload?.length
        ? payload
        : Object.values(EntityName);

      clearEntityNames.forEach((entityName: EntityName) => {
        delete state[entityName];
      });
    },
  },
});

export const dataActions = dataSlice.actions;
export const dataReducer = dataSlice.reducer;
export const dataName = dataSlice.name;
