import { createSlice } from '@reduxjs/toolkit';

import { loadStorage } from 'shared/lib/localStorage';
import { SectionName } from 'types/enums/app';
import { LocalStorageName } from 'types/enums/storage';
import { ByOwnerRouteFilter } from './lib/enums';

export interface UiState {
  [SectionName.routes]: {
    filter: {
      byOwner: ByOwnerRouteFilter,
    }
  },
}

const clearUiState: UiState = {
  [SectionName.routes]: {
    filter: {
      byOwner: ByOwnerRouteFilter.all,
    },
  },
};

const initialState: UiState = loadStorage<UiState>(LocalStorageName.ui) || clearUiState;

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {},
});

export const uiActions = uiSlice.actions;
export const uiReducer = uiSlice.reducer;
export const uiName = uiSlice.name;
