import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserModel } from 'models/UserModel';

export interface AppState {
  user?: UserModel,
  scrollingOnTop: boolean,
}

const initialState: AppState = {
  scrollingOnTop: true,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUserData(state, { payload }: PayloadAction<UserModel>) {
      state.user = payload;
    },
    clearUserData(state) {
      state.user = undefined;
    },
  },
});

export const appActions = appSlice.actions;
export const appReducer = appSlice.reducer;
export const appName = appSlice.name;
