import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Dropdown, Typography } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';

import styles from './Header.module.scss';
import useLogout from 'api/auth/useLogout';
import { Logo } from 'shared/ui/icons/logo';
import { UserOctagon } from 'shared/ui/icons/user-octagon';
import { selectUserData } from 'app/providers/selectors/app';
import environment from 'shared/config/environment';

interface Props {
  onLogout: () => void,
}

export const Header = ({ onLogout }: Props) => {
  const authBlockRef = useRef<HTMLDivElement>(null);
  const userData = useSelector(selectUserData);
  const isAuthUser = Boolean(userData);

  const {
    request: logout,
    isSuccess: isLogoutSuccess,
  } = useLogout();

  const userName = useMemo(() => {
    if (!userData) {
      return '';
    }
    const name = [userData.firstName, userData.lastName].join(' ').trim();
    return name || userData.username;
  }, [userData]);

  useEffect(() => {
    if (!isAuthUser) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://telegram.org/js/telegram-widget.js?22';
      script.setAttribute('data-telegram-login', environment.BOT_NAME);
      script.setAttribute('data-size', 'medium');
      script.setAttribute('data-auth-url', '/api/auth/login');
      script.setAttribute('data-request-access', 'write');
      authBlockRef.current?.appendChild(script);
    }
  }, [isAuthUser]);

  useEffect(() => {
    if (isLogoutSuccess) {
      onLogout();
    }
  }, [isLogoutSuccess]);

  const items: ItemType[] = [
    {
      key: '1',
      onClick: () => logout(),
      label: (
        <div>
          Выйти
        </div>
      ),
    },
  ];

  return (
    <header className={clsx(styles.wrapper)}>
      <NavLink
        to="/"
        className={clsx(styles.navLink)}
      >
        <Logo className={clsx(styles.logoIcon)} />
      </NavLink>
      <div className={clsx(styles.userName)}>
        {isAuthUser && (
          <Dropdown
            placement="bottom"
            trigger={['click']}
            menu={{ items }}
          >
            <div className={clsx(styles.userInfo)}>
              <div>
                <UserOctagon className={clsx(styles.userAvatar)} />
              </div>
              <Typography.Text
                ellipsis
                className={clsx(styles.userNameText)}
              >
                {userName}
              </Typography.Text>
            </div>
          </Dropdown>
        )}
        <div
          ref={authBlockRef}
          className={clsx(
            styles.authBlock,
            { [styles.authBlock__hidden]: isAuthUser },
          )}
        />
      </div>
    </header>
  );
};
