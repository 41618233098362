import { NavLink } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Button, Flex, Typography } from 'antd';
import clsx from 'clsx';

import styles from './Routes.module.scss';
import { RouteFeed } from './ui/route-feed';
import useFetchRoutes from 'api/route/useFetchRoutes';
import CheckableTag from 'antd/lib/tag/CheckableTag';

export const Routes = () => {
  const mainRef = useRef<HTMLDivElement>(null);
  const [scrollingOnTop, setScrollingOnTop] = useState<boolean>(true);
  const [position, setPosition] = useState(mainRef.current?.scrollTop ?? 0);
  const {
    result: routes,
    request: fetchRoutes,
    isFetching: isFetchingRoutes,
  } = useFetchRoutes();

  useEffect(() => {
    fetchRoutes();
  }, []);

  useEffect(()=> {
    const handleScroll = () => {
      const scrollTop = mainRef.current?.scrollTop ?? 0;
      const scrollHeight = mainRef.current?.scrollHeight ?? 0;
      const offsetHeight = mainRef.current?.offsetHeight ?? 0;

      const scrollingOnTop = (position > scrollTop) && scrollTop !== ((scrollHeight - offsetHeight));
      setScrollingOnTop(scrollingOnTop);

      setPosition(scrollTop > 0 ? scrollTop : 1);
    };

    mainRef.current?.addEventListener('scroll', handleScroll);

    return(() => mainRef.current?.removeEventListener('scroll', handleScroll));
  });

  if (isFetchingRoutes) {
    return <>Loading...</>;
  }

  return (
    <main
      className={styles.main}
      ref={mainRef}
    >
      <div
        className={clsx(styles.titlePanel, {
          [styles.titlePanel__visible]: scrollingOnTop,
          [styles.titlePanel__hidden]: !scrollingOnTop,
          [styles.titlePanel__transparent]: position < 24,
        })}
      >
        <Flex
          align="center"
          justify="space-between"
          className={clsx(styles.titleWrapper)}
        >
          <Typography.Title
            level={1}
            className={clsx(styles.title)}
            ellipsis={{ rows: 1 }}
          >
            Mаршруты
          </Typography.Title>
          <div>
            <CheckableTag
              checked
              className={clsx(styles.checkableTag)}
            >
              Популярные
            </CheckableTag>
            <CheckableTag
              checked={false}
              className={clsx(styles.checkableTag)}
            >
              Мои
            </CheckableTag>
          </div>
        </Flex>
      </div>
      <RouteFeed routes={routes!} />
      <Button
        type="link"
        className={clsx(styles.showMoreButton)}
      >
        РАЗВЕРНУТЬ ЕЩЁ
      </Button>
      <Flex
        justify="center"
        className={clsx(styles.addRouteButtonWrapper, {
          [styles.addRouteButtonWrapper__visible]: !scrollingOnTop,
          [styles.addRouteButtonWrapper__hidden]: scrollingOnTop,
        })}
      >
        <NavLink
          to="new"
          className={clsx(styles.addRouteButton)}
        >
          Создать свой маршрут
        </NavLink>
      </Flex>
    </main>
  );
};
