import 'antd/dist/reset.css';
import { Spin } from 'antd';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import './styles/index.scss';
import { SectionName } from 'types/enums/app';
import { Routes } from 'pages/routes';
import { Route } from 'pages/route';
import { PageLayout } from './ui/page-layout';
import { withStore } from './providers/with-store';

export const router = createBrowserRouter([
  {
    path: '',
    element: <Navigate replace to={`/${SectionName.routes}`} />,
  },
  {
    path: '/',
    element: <PageLayout />,
    children: [
      {
        path: `${SectionName.routes}`,
        element: <Routes />,
      },
      {
        path: `${SectionName.routes}/:routeId`,
        element: <Route />,
      },
    ],
  },
]);

export const AppRouter = () => (
  <RouterProvider
    router={router}
    fallbackElement={<Spin />}
  />
);

export const App = withStore(AppRouter);
