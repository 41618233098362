import { RouteModel, RoutePoint } from 'models/RouteModel';

export type RoutePropName = keyof RouteModel;

export type RoutePointPropName = keyof RoutePoint;

export namespace RoutePropNames {
  export const id: RoutePropName = 'id';
  export const description: RoutePropName = 'description';
  export const points: RoutePropName = 'points';
  export const userId: RoutePropName = 'userId';
  export const price: RoutePropName = 'price';
  export const driversCount: RoutePropName = 'driversCount';
  export const passengersCount: RoutePropName = 'passengersCount';
  export const tariff: RoutePropName = 'tariff';
  export const date: RoutePropName = 'date';
}

export namespace RoutePointPropNames {
  export const locality: RoutePointPropName = 'locality';
  export const description: RoutePointPropName = 'description';
  export const images: RoutePointPropName = 'images';
}
