import { useState } from 'react';

import environment from 'shared/config/environment';
import { Env } from 'types/enums/env';

interface Query<Result> {
  isSuccess: boolean,
  isFetching: boolean,
  isError: boolean,
  result?: Result,
  error?: Error,
}

export type RequestQueryValue = string | number | boolean | null | undefined;

export type PlainObject = Record<string, unknown> | object;
export type QueryParams = Record<string, RequestQueryValue>;
export type Headers = Record<string, string>;

export enum Method {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  PUT = 'put',
  DELETE = 'delete',
};

interface RequestParams<Body> {
  headers: Headers,
  query: QueryParams,
  body: Body,
};

const useQuery = <Result, Params extends RequestParams<Body> | PlainObject | void = void>(
  path: string,
  method = Method.GET,
) => {
  const [state, setState] = useState<Query<Result>>({
    isSuccess: false,
    isFetching: false,
    isError: false,
  });

  const request: (params: Params) => void = (params) => {
    setState((state) => ({
      ...state,
      isFetching: true,
    }));

    const url = (() => {
      let queryString = '';
      if (params !== void 0 && (params as RequestParams<Body>).query) {
        const query = (params as RequestParams<Body>).query;
        const tmp = Object.entries(query)
          .reduce((acc, [key, value]) => {
            if (value === undefined || value === '' || value === null) {
              return acc;
            }
            const param = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            return [...acc, param];
          }, [] as RequestQueryValue[])
          .join('&');
        if (tmp) {
          queryString = `?${tmp}`;
        }
      }

      if (environment.ENV === Env.local) {
        return `${environment.PROTOCOL}://${environment.HOST}/api${path}${queryString}`;
      }

      return `/api${path}${queryString}`;
    })();

    const headers = { 'Content-Type': 'application/json', ...(params as RequestParams<Body>)?.headers };

    const body = (() => {
      if ((params as RequestParams<Body>)?.body) {
        return JSON.stringify((params as RequestParams<Body>).body);
      } else {
        return params ? JSON.stringify(params) : undefined;
      }
    })();

    let isSuccess = false;
    let isSetBody = false;
    fetch(url, { method, headers, body })
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        isSuccess = true;
        return response.json();
      })
      .then((result) => {
        isSetBody = true;
        setState({
          result,
          isSuccess: true,
          isFetching: false,
          isError: false,
        });
      })
      .catch((error) => setState({
        error,
        isSuccess: false,
        isFetching: false,
        isError: true,
      }))
      .finally(() => {
        if (!isSetBody && isSuccess) {
          setState({
            isSuccess: true,
            isFetching: false,
            isError: false,
          });
        }
      });
  };

  return { ...state, request };
};

export default useQuery;
