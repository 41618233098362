import { useMatch } from 'react-router-dom';
import { useMemo } from 'react';
import clsx from 'clsx';

import styles from './Footer.module.scss';
import { Route as RouteIcon } from 'shared/ui/icons/route';
import { Car as CarIcon } from 'shared/ui/icons/car';
import { Telegram as TelegramIcon } from 'shared/ui/icons/telegram/Telegram';
import { MenuItem, Props as MenuItemProps } from './ui/menu-item/MenuItem';
import { SectionName } from 'types/enums/app';
import environment from 'shared/config/environment';

export const Footer = () => {
  const matchPath = useMatch('/:sectionName');
  const sectionName = matchPath?.params.sectionName;

  const menuItems: MenuItemProps[] = useMemo(() => {
    return [
      {
        title: 'Маршруты',
        icon: <RouteIcon />,
        path: SectionName.routes,
        active: sectionName === SectionName.routes,
      },
      {
        title: 'Voyagers Bot',
        icon: <TelegramIcon />,
        path: `https://t.me/${environment.BOT_NAME}`,
        active: sectionName === SectionName.dates,
      },
    ];
  }, [sectionName]);

  return (
    <footer className={clsx(styles.wrapper)}>
      {menuItems.map((props) => (
        <MenuItem
          key={props.path}
          {...props}
        />
      ))}
    </footer>
  );
};
